<template>
  <v-dialog v-model="showAjudadialog">
    <v-card>
      <v-form>
        <v-container>
          <v-row align="center" align-content="center" justify="center">
            <v-col cols="12" class="text-left title">
              <v-icon left>fas fa-question-circle</v-icon>Ajuda
            </v-col>
            <v-col cols="12" class="text-left subtitle-1"
              >
              <label>Versão: {{appBuildVersion}}</label></v-col
            >

            <v-col cols="6">
              <v-btn outlined
                block
                color="primary"
                class="grey--text"
                @click="closeDialog()"
              >
                <v-icon left>fas fa-check</v-icon>Ok
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    appBuildVersion () {
      return process.env.VUE_APP_BUILD_VERSION;
    },
    showAjudadialog: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        this.$emit("update:dialog", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.showAjudadialog = false;
    },
  }
};
</script>
