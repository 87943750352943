<template>
  <v-container fluid pa-0>
    <cadastroDialog :dialog.sync="dialog" />
    <ajuda-dialog :dialog.sync="ajudaDialog" />

    <v-row no-gutters align="start" justify="center" v-if="!dialog && !ajudaDialog" :key="app.reloadableKey">
      <v-col cols="12">
        <v-list v-if="user" class="transparent pa-0">
          <v-list-item @click="$util.link('perfil')">
            <v-list-item-avatar size="60">
              <img :src="user.profilePicture" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="ml-2">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mb-3"></v-divider>
          <v-list-item @click="$util.link('perfil')">
            <v-list-item-content>
              <v-list-item-title class>Meu Perfil</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-user</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <v-divider class="my-3"></v-divider>
          <v-list-item @click="$router.push('extrato-de-utilizacao')">
            <v-list-item-content>
              <v-list-item-title>Extrato de Utilização</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-scroll</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <template v-if="!$auth.userIsSF()">
            <v-divider class="my-3"></v-divider>
            <v-list-item @click="conectarEmpresa()">
              <v-list-item-content>
                <v-list-item-title class>Conectar com a sua empresa</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-btn color="secondary" icon class="elevation-1">
                  <v-icon small color="black">fas fa-plug</v-icon>
                </v-btn>
              </v-list-item-avatar>
            </v-list-item>
          </template>

          <v-divider class="my-3"></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class>Formas de Pagamento</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-wallet</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <v-divider class="my-3"></v-divider>
          <v-list-item @click="$util.linkNewTab('politicadeprivacidade')">
            <v-list-item-content>
              <v-list-item-title class>Politica de Privacidade</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-user-shield</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <v-divider class="my-3"></v-divider>
          <v-list-item @click="ajuda()">
            <v-list-item-content>
              <v-list-item-title class>Ajuda</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-question-circle</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <div class="mt-auto mb-auto" />

          <v-divider class="my-3"></v-divider>
          <v-list-item @click="logout()">
            <v-list-item-content>
              <v-list-item-title class>Sair</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-btn color="secondary" icon class="elevation-1">
                <v-icon small color="black">fas fa-sign-out-alt</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import cadastroDialog from "./../app/cadastroDialog.vue";
import AjudaDialog from "../app/ajudaDialog";

export default {
  data() {
    return {
      dialog: false,
      ajudaDialog: false
    };
  },
  methods: {
    ...mapMutations(["updateMenuState", "toogleMainDialog"]),
    link(name) {
      this.$router.push({ name: name });
    },
    logout: function() {
      this.$auth.logout();
    },
    conectarEmpresa() {
      this.dialog = true;
    },
    ajuda(){
      this.ajudaDialog = true;
    }
  },
  computed: {
    ...mapState(["menu", "app"]),
    menur: {
      get: function() {
        return this.menu.rigth;
      },
      set: function(value) {
        this.updateMenuState({ rigth: value });
      }
    },
    user(){
      return this.$auth.user();
    }
  },
  components: {
    AjudaDialog,
    cadastroDialog
  }
};
</script>

<style>
</style>
